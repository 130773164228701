import { MessageReducer } from "../helpers/MessageReducer";
import Config from "../config";
import { AxiosInstance } from "../helpers/AxiosInstance";

let messages = [];
let contactTypes = [];

const sendNewMessage = (data, fileName, fileType) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;

    return AxiosInstance.post(`${Config.API_URL}/api/Messages/Send`, data, config);
}

const sendInvite = (data, inviteId) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/invite/${inviteId}`, data);
}

const getFaceRegistrationStatus = (contactId) => {
    return AxiosInstance.get(`${Config.API_SECOND_URL}/securefileshare/api/v1/faceStatus/${contactId}`);
}

const sendGroupMessage = (data, fileName, fileType, campaignName, campaignId) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/SendGroup?campaignName=${campaignName}&campaignId=${campaignId}`, data, config);
}

const sendGroupChatMessage = (data, fileName, groupChatName, fileType) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/SendGroupChat?groupChatName=${groupChatName}`, data, config);
}

const createScheduleMessage = (data, fileName, fileType, camopaignName) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/Schedule`, data, config);
}

const updateScheduleMessage = (id, data, fileName, fileType) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.put(`${Config.API_URL}/api/Messages/${id}/Schedule`, data, config);
}

const updateScheduleMessageStatus = (id) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Messages/${id}/Schedule/Status`);
}

const deleteScheduleMessage = (id) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Messages/${id}/Schedule`);
}

const getScheduleMessage = (phoneId) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/Schedule?phoneNumberId=${phoneId}`);
}

const SetPinnedMessagesOrder = (pinned) => {
    contactTypes
        .filter((x) => x.isPinned)
        .map((y) => {
            pinned.filter((item) => {
                return (
                    y.phoneNumber == item.phoneNumber &&
                    !!(item.pinnedOrder = y.createdDateTime)
                );
            });
            return y.phoneNumber;
        });
    return pinned.sort(
        (a, b) => new Date(a.pinnedOrder) - new Date(b.pinnedOrder)
    );
}

const formatMessages = (newMessage) => {
    var normal = [],
        pinned = [],
        archived = [],
        unreadCount = 0;
    if (!contactTypes || contactTypes.length == 0) {
        normal = messages.map((y) => {
            y.archived = false;
            y.pinned = false;
            if (y.unReadCount != 0)
                unreadCount++;
            return y;
        });
    } else {
        var aNumbers = contactTypes
            .filter((x) => x.isArchived)
            .map((y) => {
                return y.phoneNumber;
            });
        var pNumbers = contactTypes
            .filter((x) => x.isPinned)
            .map((y) => {
                return y.phoneNumber;
            });
        archived = messages
            .filter((item) => {
                if (item.unReadCount != 0)
                    unreadCount++;
                return aNumbers.includes(item.phoneNumber);
            })
            .map((y) => {
                y.archived = true;
                y.pinned = false;
                return y;
            });
        pinned = messages
            .filter((item) => {
                return pNumbers.includes(item.phoneNumber);
            })
            .map((y) => {
                y.archived = false;
                y.pinned = true;
                return y;
            });
        normal = messages
            .filter((item) => {
                return (
                    !pNumbers.includes(item.phoneNumber) &&
                    !aNumbers.includes(item.phoneNumber)
                );
            })
            .map((y) => {
                y.archived = false;
                y.pinned = false;
                return y;
            });
        pinned = SetPinnedMessagesOrder(pinned);
    }

    if (document.getElementById("SideBar-Conversations")) {
        var text = document.getElementById("SideBar-Conversations").getAttribute("text");
        if (unreadCount != 0)
            text = text + " (" + unreadCount + ")";
        document.getElementById("SideBar-Conversations").firstChild.innerHTML = text;
    }

    return {
        normal: normal,
        archived: archived,
        pinned: pinned,
        newMessage: newMessage,
    };
}

const getContactTypes = () => {
    if (contactTypes.length) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(contactTypes);
            }, 100);
        });
    } else {
        return AxiosInstance.get(`${Config.API_URL}/api/ContactTypes`).then((res) => {
            contactTypes = res.data;
            return res.data;
        });
    }
}

const getMessages = (load) => {
    if (messages.length && !load) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(formatMessages());
            }, 100);
        });
    } else {
        return getContactTypes().then((res) => {
            contactTypes = res;
            return AxiosInstance.get(`${Config.API_URL}/api/Messages`).then((res1) => {
                messages = res1.data;
                return formatMessages();
            });
        });
    }
}

const getHistory = (id, pages) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/conversation/${id}/${pages}`);
}

const getGroupChatHistory = (GroupChatId, pages) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/groupconversation/${GroupChatId}/${pages}`);
}

const getCampaignChatHistory = (campaignId, pages) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/campaign/${campaignId}/${pages}`);
}
const getCampaignMessageReport = (AuditId) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/Audit/Id/${AuditId}`);
}

const downloadHistory = (id) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/conversation/${id}/download`, { responseType: 'arraybuffer' });
}

const deleteHistory = (number) => {
    var num = number[0] == "+" ? number.slice(1) : number;
    return AxiosInstance.delete(`${Config.API_URL}/api/Messages/${num}`).then((res) => {
        var index = getIndex(number);
        if (index >= 0) messages.splice(index, 1);

        var archived = contactTypes.filter((x) => x.phoneNumber == number);
        if (archived.length) {
            archived[0].isArchived = false;
            archived[0].isPinned = false;
        }

        MessageReducer.dispatch({ type: "add", data: formatMessages(false) });
        return res;
    });
}

const deleteGroupChatHistory = (groupChatId) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Messages/groupchat/${groupChatId}`)
}
const deleteCampaignHistory = (campaignId) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Campaign/campaign/${campaignId}`)
}

const deleteMessages = (phoneNumber, messages, media) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Messages`, {
        data: {
            "contactNumber": phoneNumber,
            "idList": messages,
            "mediaIdList": media
        }
    });
}

const deleteGroupChatMessages = (groupChatId, messages, media) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Messages/groupchat`, {
        data: {
            "groupChatId": groupChatId,
            "idList": messages,
            "mediaIdList": media
        }
    });
}

const getIndex = (phoneNumber) => {
    var index = -1;
    for (var i = 0; i < messages.length; i++) {
        if (messages[i].phoneNumber == phoneNumber) {
            index = i;
            break;
        }
    }
    return index;
}

const setContactAsPinned = (number, isPinned) => {
    number = number[0] == "+" ? number.slice(1) : number;
    return AxiosInstance.patch(`${Config.API_URL}/api/ContactTypes/${number}/pin/${isPinned}`).then(
        (res) => {
            contactTypes = res.data;
            MessageReducer.dispatch({ type: "add", data: formatMessages() });
            return res.data;
        }
    );
}

const setContactAsArchived = (number, isArchived) => {
    number = number.replace(/\+/g, "");
    return AxiosInstance.patch(`${Config.API_URL}/api/ContactTypes/${number}/archive/${isArchived}`).then(
        (res) => {
            contactTypes = res.data;
            MessageReducer.dispatch({ type: "add", data: formatMessages() });
            return res.data;
        }
    );
}


const blockUnBlockNumber = (number, isBlocked) => {
    var index = getIndex(number);
    if (index >= 0) {
        messages[index].isBlocked = isBlocked;
        MessageReducer.dispatch({ type: "add", data: formatMessages() });
    }
}

const setUnreadMessageCount = (number, count) => {
    var index = getIndex(number);
    if (index >= 0) {
        messages[index].unReadCount = count;
        MessageReducer.dispatch({ type: "add", data: formatMessages() });
    }
}



const muteConversation = (phoneNumber, duration) => {
    phoneNumber = phoneNumber.replace("+", "");
    return AxiosInstance.patch(`${Config.API_URL}/api/ContactTypes/${phoneNumber}/mute/${duration}`).then(
        (res) => {

            return res.data;
        }
    );
}


const scheduleMessagesMassAction = (action, data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/SchMsgMassAction?action=${action}`, data);
}


const downloadHistoryText = (number) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/conversation/${number}/text`);
}

const downloadGroupChatHistory = (groupchatId) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Messages/groupconversation/${groupchatId}/text`);
}

const groupChatReadOrUnread = (groupchatId, count) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Messages/${groupchatId}/SetRead/${count}`).then(res => {
        return res;
    });
}


const sendTypingEvent = (receiverPhoneNumber, action) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/typing?receiverPhoneNumber=${receiverPhoneNumber}&action=${action}`)

}

const conversationsMassActionDelete = (action, data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Messages/MassActions?action=${action}`, data)
}

const conversationsMassActionArchive = (action, value, data) => {
    return AxiosInstance.post(`${Config.API_URL}/api/ContactTypes/MassActions?action=${action}&value=${value}`, data).then(res => {
        return res.data;
    });
}



export const MessageService = {
    getMessages,
    getHistory,
    getGroupChatHistory,
    setContactAsPinned,
    setContactAsArchived,
    deleteHistory,
    blockUnBlockNumber,
    setUnreadMessageCount,
    sendNewMessage,
    sendInvite,
    getFaceRegistrationStatus,
    sendGroupMessage,
    sendGroupChatMessage,
    getContactTypes,
    downloadHistory,
    deleteMessages,
    muteConversation,
    createScheduleMessage,
    getScheduleMessage,
    updateScheduleMessage,
    scheduleMessagesMassAction,
    updateScheduleMessageStatus,
    deleteScheduleMessage,
    downloadHistoryText,
    deleteGroupChatHistory,
    deleteGroupChatMessages,
    downloadGroupChatHistory,
    groupChatReadOrUnread,
    sendTypingEvent,
    getCampaignChatHistory,
    deleteCampaignHistory,
    getCampaignMessageReport,
    conversationsMassActionDelete,
    conversationsMassActionArchive,
};